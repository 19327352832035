import store from '@/store'

import messagesBaseEn from '@/i18n/messages/en'
import messagesBasePl from '@/i18n/messages/pl'

import messagesFooterEn from '@/i18n/messages/components/footer.en'
import messagesFooterPl from '@/i18n/messages/components/footer.pl'

import messagesPagerEn from '@/i18n/messages/components/pager.en'
import messagesPagerPl from '@/i18n/messages/components/pager.pl'

import messagesScoreEn from '@/i18n/messages/components/score.en'
import messagesScorePl from '@/i18n/messages/components/score.pl'

import messagesUploadEn from '@/i18n/messages/components/upload.en'
import messagesUploadPl from '@/i18n/messages/components/upload.pl'

import messagesUsernameEn from '@/i18n/messages/components/username.en'
import messagesUsernamePl from '@/i18n/messages/components/username.pl'

import messagesAccountIndexEn from '@/i18n/messages/views/account.index.en'
import messagesAccountIndexPl from '@/i18n/messages/views/account.index.pl'

import messagesAccountAnonymizeEn from '@/i18n/messages/views/account.anonymize.en'
import messagesAccountAnonymizePl from '@/i18n/messages/views/account.anonymize.pl'

import messagesAccountChangeAvatarEn from '@/i18n/messages/views/account.changeAvatar.en'
import messagesAccountChangeAvatarPl from '@/i18n/messages/views/account.changeAvatar.pl'

import messagesAccountChangeEmailEn from '@/i18n/messages/views/account.changeEmail.en'
import messagesAccountChangeEmailPl from '@/i18n/messages/views/account.changeEmail.pl'

import messagesAccountChangePasswordEn from '@/i18n/messages/views/account.changePassword.en'
import messagesAccountChangePasswordPl from '@/i18n/messages/views/account.changePassword.pl'

import messagesAccountRequestActivationEn from '@/i18n/messages/views/account.requestActivation.en'
import messagesAccountRequestActivationPl from '@/i18n/messages/views/account.requestActivation.pl'

import messagesAccountResetPasswordEn from '@/i18n/messages/views/account.resetPassword.en'
import messagesAccountResetPasswordPl from '@/i18n/messages/views/account.resetPassword.pl'

import messagesAccountRevokeSessionsEn from '@/i18n/messages/views/account.revokeSessions.en'
import messagesAccountRevokeSessionsPl from '@/i18n/messages/views/account.revokeSessions.pl'

import messagesAccountWarningsEn from '@/i18n/messages/views/account.warnings.en'
import messagesAccountWarningsPl from '@/i18n/messages/views/account.warnings.pl'

import messagesAccountLoginEn from '@/i18n/messages/views/account.login.en'
import messagesAccountLoginPl from '@/i18n/messages/views/account.login.pl'

import messagesAccountRegisterEn from '@/i18n/messages/views/account.register.en'
import messagesAccountRegisterPl from '@/i18n/messages/views/account.register.pl'

import messagesBlogBlogEn from '@/i18n/messages/views/blog.blog.en'
import messagesBlogBlogPl from '@/i18n/messages/views/blog.blog.pl'

import messagesBlogEditEn from '@/i18n/messages/views/blog.edit.en'
import messagesBlogEditPl from '@/i18n/messages/views/blog.edit.pl'

import messagesBlogIndexEn from '@/i18n/messages/views/blog.index.en'
import messagesBlogIndexPl from '@/i18n/messages/views/blog.index.pl'

import messagesBlogNewEn from '@/i18n/messages/views/blog.new.en'
import messagesBlogNewPl from '@/i18n/messages/views/blog.new.pl'

import messagesCalculatorsIndexEn from '@/i18n/messages/views/calculators.index.en'
import messagesCalculatorsIndexPl from '@/i18n/messages/views/calculators.index.pl'

import messagesCalculatorsAgeCalculatorEn from '@/i18n/messages/views/calculators.ageCalculator.en'
import messagesCalculatorsAgeCalculatorPl from '@/i18n/messages/views/calculators.ageCalculator.pl'

import messagesCalculatorsEquipmentLootBonusCalculatorEn from '@/i18n/messages/views/calculators.equipmentLootBonusCalculator.en'
import messagesCalculatorsEquipmentLootBonusCalculatorPl from '@/i18n/messages/views/calculators.equipmentLootBonusCalculator.pl'

import messagesCalculatorsFishingCalculatorEn from '@/i18n/messages/views/calculators.fishingCalculator.en'
import messagesCalculatorsFishingCalculatorPl from '@/i18n/messages/views/calculators.fishingCalculator.pl'

import messagesCalculatorsHuntingTimeCalculatorEn from '@/i18n/messages/views/calculators.huntingTimeCalculator.en'
import messagesCalculatorsHuntingTimeCalculatorPl from '@/i18n/messages/views/calculators.huntingTimeCalculator.pl'

import messagesCalculatorsPartyCalculatorEn from '@/i18n/messages/views/calculators.partyCalculator.en'
import messagesCalculatorsPartyCalculatorPl from '@/i18n/messages/views/calculators.partyCalculator.pl'

import messagesCalculatorsPlayerStatsCalculatorEn from '@/i18n/messages/views/calculators.playerStatsCalculator.en'
import messagesCalculatorsPlayerStatsCalculatorPl from '@/i18n/messages/views/calculators.playerStatsCalculator.pl'

import messagesCalculatorsStaminaCalculatorEn from '@/i18n/messages/views/calculators.staminaCalculator.en'
import messagesCalculatorsStaminaCalculatorPl from '@/i18n/messages/views/calculators.staminaCalculator.pl'

import messagesErrorEn from '@/i18n/messages/views/error.en'
import messagesErrorPl from '@/i18n/messages/views/error.pl'

import messagesForumsForumEn from '@/i18n/messages/views/forums.forum.en'
import messagesForumsForumPl from '@/i18n/messages/views/forums.forum.pl'

import messagesFansiteItemEn from '@/i18n/messages/views/fansiteItem.en'
import messagesFansiteItemPl from '@/i18n/messages/views/fansiteItem.pl'

import messagesForumsIndexEn from '@/i18n/messages/views/forums.index.en'
import messagesForumsIndexPl from '@/i18n/messages/views/forums.index.pl'

import messagesImagesEditEn from '@/i18n/messages/views/images.edit.en'
import messagesImagesEditPl from '@/i18n/messages/views/images.edit.pl'

import messagesImagesImageEn from '@/i18n/messages/views/images.image.en'
import messagesImagesImagePl from '@/i18n/messages/views/images.image.pl'

import messagesImagesIndexEn from '@/i18n/messages/views/images.index.en'
import messagesImagesIndexPl from '@/i18n/messages/views/images.index.pl'

import messagesImagesNewEn from '@/i18n/messages/views/images.new.en'
import messagesImagesNewPl from '@/i18n/messages/views/images.new.pl'

import messagesLibraryIndexEn from '@/i18n/messages/views/library.index.en'
import messagesLibraryIndexPl from '@/i18n/messages/views/library.index.pl'

import messagesLibraryAchievementsAchievementEn from '@/i18n/messages/views/library.achievements.achievement.en'
import messagesLibraryAchievementsAchievementPl from '@/i18n/messages/views/library.achievements.achievement.pl'

import messagesLibraryAchievementsIndexEn from '@/i18n/messages/views/library.achievements.index.en'
import messagesLibraryAchievementsIndexPl from '@/i18n/messages/views/library.achievements.index.pl'

import messagesLibraryCreaturesIndexEn from '@/i18n/messages/views/library.creatures.index.en'
import messagesLibraryCreaturesIndexPl from '@/i18n/messages/views/library.creatures.index.pl'

import messagesLibraryCreaturesCreatureEn from '@/i18n/messages/views/library.creatures.creature.en'
import messagesLibraryCreaturesCreaturePl from '@/i18n/messages/views/library.creatures.creature.pl'

import messagesLibraryBooksIndexEn from '@/i18n/messages/views/library.books.index.en'
import messagesLibraryBooksIndexPl from '@/i18n/messages/views/library.books.index.pl'

import messagesLibraryBooksBookEn from '@/i18n/messages/views/library.books.book.en'
import messagesLibraryBooksBookPl from '@/i18n/messages/views/library.books.book.pl'

import messagesLibraryItemsIndexEn from '@/i18n/messages/views/library.items.index.en'
import messagesLibraryItemsIndexPl from '@/i18n/messages/views/library.items.index.pl'

import messagesLibraryItemsAmuletsEn from '@/i18n/messages/views/library.items.amulets.en'
import messagesLibraryItemsAmuletsPl from '@/i18n/messages/views/library.items.amulets.pl'

import messagesLibraryItemsArmorsEn from '@/i18n/messages/views/library.items.armors.en'
import messagesLibraryItemsArmorsPl from '@/i18n/messages/views/library.items.armors.pl'

import messagesLibraryItemsBootsEn from '@/i18n/messages/views/library.items.boots.en'
import messagesLibraryItemsBootsPl from '@/i18n/messages/views/library.items.boots.pl'

import messagesLibraryItemsContainersEn from '@/i18n/messages/views/library.items.containers.en'
import messagesLibraryItemsContainersPl from '@/i18n/messages/views/library.items.containers.pl'

import messagesLibraryItemsDistanceWeaponsEn from '@/i18n/messages/views/library.items.distanceWeapons.en'
import messagesLibraryItemsDistanceWeaponsPl from '@/i18n/messages/views/library.items.distanceWeapons.pl'

import messagesLibraryItemsFoodEn from '@/i18n/messages/views/library.items.food.en'
import messagesLibraryItemsFoodPl from '@/i18n/messages/views/library.items.food.pl'

import messagesLibraryItemsHelmetsEn from '@/i18n/messages/views/library.items.helmets.en'
import messagesLibraryItemsHelmetsPl from '@/i18n/messages/views/library.items.helmets.pl'

import messagesLibraryItemsLegsEn from '@/i18n/messages/views/library.items.legs.en'
import messagesLibraryItemsLegsPl from '@/i18n/messages/views/library.items.legs.pl'

import messagesLibraryItemsLiquidsEn from '@/i18n/messages/views/library.items.liquids.en'
import messagesLibraryItemsLiquidsPl from '@/i18n/messages/views/library.items.liquids.pl'

import messagesLibraryItemsMeleeWeaponsEn from '@/i18n/messages/views/library.items.meleeWeapons.en'
import messagesLibraryItemsMeleeWeaponsPl from '@/i18n/messages/views/library.items.meleeWeapons.pl'

import messagesLibraryItemsOthersEn from '@/i18n/messages/views/library.items.others.en'
import messagesLibraryItemsOthersPl from '@/i18n/messages/views/library.items.others.pl'

import messagesLibraryItemsRingsEn from '@/i18n/messages/views/library.items.rings.en'
import messagesLibraryItemsRingsPl from '@/i18n/messages/views/library.items.rings.pl'

import messagesLibraryItemsShieldsEn from '@/i18n/messages/views/library.items.shields.en'
import messagesLibraryItemsShieldsPl from '@/i18n/messages/views/library.items.shields.pl'

import messagesLibraryItemsToolsEn from '@/i18n/messages/views/library.items.tools.en'
import messagesLibraryItemsToolsPl from '@/i18n/messages/views/library.items.tools.pl'

import messagesLibraryItemsValuablesEn from '@/i18n/messages/views/library.items.valuables.en'
import messagesLibraryItemsValuablesPl from '@/i18n/messages/views/library.items.valuables.pl'

import messagesLibraryItemsCreatureProductsEn from '@/i18n/messages/views/library.items.creatureProducts.en'
import messagesLibraryItemsCreatureProductsPl from '@/i18n/messages/views/library.items.creatureProducts.pl'

import messagesLibraryItemsCurrencyEn from '@/i18n/messages/views/library.items.currency.en'
import messagesLibraryItemsCurrencyPl from '@/i18n/messages/views/library.items.currency.pl'

import messagesLibraryItemsItemEn from '@/i18n/messages/views/library.items.item.en'
import messagesLibraryItemsItemPl from '@/i18n/messages/views/library.items.item.pl'

import messagesLibraryNpcsIndexEn from '@/i18n/messages/views/library.npcs.index.en'
import messagesLibraryNpcsIndexPl from '@/i18n/messages/views/library.npcs.index.pl'

import messagesLibraryNpcsNpcEn from '@/i18n/messages/views/library.npcs.npc.en'
import messagesLibraryNpcsNpcPl from '@/i18n/messages/views/library.npcs.npc.pl'

import messagesPostsIndexEn from '@/i18n/messages/views/posts.index.en'
import messagesPostsIndexPl from '@/i18n/messages/views/posts.index.pl'

import messagesPrivacyPolicyEn from '@/i18n/messages/views/privacyPolicy.en'
import messagesPrivacyPolicyPl from '@/i18n/messages/views/privacyPolicy.pl'

import messagesRookstatAdvancementsEn from '@/i18n/messages/views/rookstat.advancements.en'
import messagesRookstatAdvancementsPl from '@/i18n/messages/views/rookstat.advancements.pl'

import messagesRookstatBoostedCreaturesEn from '@/i18n/messages/views/rookstat.boostedCreatures.en'
import messagesRookstatBoostedCreaturesPl from '@/i18n/messages/views/rookstat.boostedCreatures.pl'

import messagesRookstatBossesEn from '@/i18n/messages/views/rookstat.bosses.en'
import messagesRookstatBossesPl from '@/i18n/messages/views/rookstat.bosses.pl'

import messagesRookstatDeathsEn from '@/i18n/messages/views/rookstat.deaths.en'
import messagesRookstatDeathsPl from '@/i18n/messages/views/rookstat.deaths.pl'

import messagesRookstatFrequentlyAskedQuestionsEn from '@/i18n/messages/views/rookstat.frequentlyAskedQuestions.en'
import messagesRookstatFrequentlyAskedQuestionsPl from '@/i18n/messages/views/rookstat.frequentlyAskedQuestions.pl'

import messagesRookstatIndexEn from '@/i18n/messages/views/rookstat.index.en'
import messagesRookstatIndexPl from '@/i18n/messages/views/rookstat.index.pl'

import messagesRookstatPlayersIndexEn from '@/i18n/messages/views/rookstat.players.index.en'
import messagesRookstatPlayersIndexPl from '@/i18n/messages/views/rookstat.players.index.pl'

import messagesRookstatPlayersPlayerEn from '@/i18n/messages/views/rookstat.players.player.en'
import messagesRookstatPlayersPlayerPl from '@/i18n/messages/views/rookstat.players.player.pl'

import messagesRookstatSpecialCodesEn from '@/i18n/messages/views/rookstat.specialCodes.en'
import messagesRookstatSpecialCodesPl from '@/i18n/messages/views/rookstat.specialCodes.pl'

import messagesRookstatWorldsEn from '@/i18n/messages/views/rookstat.worlds.en'
import messagesRookstatWorldsPl from '@/i18n/messages/views/rookstat.worlds.pl'

import messagesRulesEn from '@/i18n/messages/views/rules.en'
import messagesRulesPl from '@/i18n/messages/views/rules.pl'

import messagesThreadsNewEn from '@/i18n/messages/views/threads.new.en'
import messagesThreadsNewPl from '@/i18n/messages/views/threads.new.pl'

import messagesThreadsNewPostEn from '@/i18n/messages/views/threads.newPost.en'
import messagesThreadsNewPostPl from '@/i18n/messages/views/threads.newPost.pl'

import messagesThreadsThreadEn from '@/i18n/messages/views/threads.thread.en'
import messagesThreadsThreadPl from '@/i18n/messages/views/threads.thread.pl'

import messagesThreadsEditEn from '@/i18n/messages/views/threads.edit.en'
import messagesThreadsEditPl from '@/i18n/messages/views/threads.edit.pl'

import messagesThreadsEditPostEn from '@/i18n/messages/views/threads.editPost.en'
import messagesThreadsEditPostPl from '@/i18n/messages/views/threads.editPost.pl'

import messagesUsersIndexEn from '@/i18n/messages/views/users.index.en'
import messagesUsersIndexPl from '@/i18n/messages/views/users.index.pl'

import messagesUsersUserEn from '@/i18n/messages/views/users.user.en'
import messagesUsersUserPl from '@/i18n/messages/views/users.user.pl'

import messagesWarningsNewEn from '@/i18n/messages/views/warnings.new.en'
import messagesWarningsNewPl from '@/i18n/messages/views/warnings.new.pl'

import deepmerge from 'deepmerge'

import { createI18n } from 'vue-i18n'

const getLocale = () => {
  const { locale } = store.state.locale

  if (typeof window !== 'undefined') {
    const { language } = window.navigator

    return locale ?? language
      .split('-')[0]
      ?.toLowerCase()
  }

  return locale ?? 'en'
}

let locale = getLocale()

if (!['en', 'pl'].includes(locale)) {
  locale = 'en'
}

if (!store.state.lang.language) {
  store.commit('SET_LANGUAGE', locale)
}

if (!store.state.locale.locale) {
  store.commit('SET_LOCALE', locale)
}

const messagesEn: object[] = [
  messagesFooterEn,
  messagesPagerEn,
  messagesScoreEn,
  messagesUploadEn,
  messagesUsernameEn,

  messagesAccountIndexEn,
  messagesAccountAnonymizeEn,
  messagesAccountChangeAvatarEn,
  messagesAccountChangeEmailEn,
  messagesAccountChangePasswordEn,
  messagesAccountLoginEn,
  messagesAccountRegisterEn,
  messagesAccountRequestActivationEn,
  messagesAccountResetPasswordEn,
  messagesAccountRevokeSessionsEn,
  messagesAccountWarningsEn,

  messagesBlogBlogEn,
  messagesBlogEditEn,
  messagesBlogIndexEn,
  messagesBlogNewEn,

  messagesCalculatorsIndexEn,
  messagesCalculatorsAgeCalculatorEn,
  messagesCalculatorsEquipmentLootBonusCalculatorEn,
  messagesCalculatorsFishingCalculatorEn,
  messagesCalculatorsHuntingTimeCalculatorEn,
  messagesCalculatorsPartyCalculatorEn,
  messagesCalculatorsPlayerStatsCalculatorEn,
  messagesCalculatorsStaminaCalculatorEn,

  messagesErrorEn,

  messagesImagesEditEn,
  messagesImagesImageEn,
  messagesImagesIndexEn,
  messagesImagesNewEn,

  messagesForumsForumEn,

  messagesFansiteItemEn,

  messagesForumsIndexEn,

  messagesLibraryIndexEn,
  messagesLibraryAchievementsAchievementEn,
  messagesLibraryAchievementsIndexEn,
  messagesLibraryBooksIndexEn,
  messagesLibraryBooksBookEn,
  messagesLibraryItemsIndexEn,
  messagesLibraryItemsAmuletsEn,
  messagesLibraryItemsArmorsEn,
  messagesLibraryItemsBootsEn,
  messagesLibraryItemsContainersEn,
  messagesLibraryItemsDistanceWeaponsEn,
  messagesLibraryItemsFoodEn,
  messagesLibraryItemsHelmetsEn,
  messagesLibraryItemsLegsEn,
  messagesLibraryItemsLiquidsEn,
  messagesLibraryItemsMeleeWeaponsEn,
  messagesLibraryItemsOthersEn,
  messagesLibraryItemsRingsEn,
  messagesLibraryItemsShieldsEn,
  messagesLibraryItemsToolsEn,
  messagesLibraryItemsValuablesEn,
  messagesLibraryItemsCreatureProductsEn,
  messagesLibraryItemsCurrencyEn,
  messagesLibraryItemsItemEn,
  messagesLibraryCreaturesIndexEn,
  messagesLibraryCreaturesCreatureEn,
  messagesLibraryNpcsIndexEn,
  messagesLibraryNpcsNpcEn,

  messagesPostsIndexEn,

  messagesPrivacyPolicyEn,

  messagesRookstatAdvancementsEn,
  messagesRookstatBoostedCreaturesEn,
  messagesRookstatBossesEn,
  messagesRookstatDeathsEn,
  messagesRookstatFrequentlyAskedQuestionsEn,
  messagesRookstatIndexEn,
  messagesRookstatPlayersIndexEn,
  messagesRookstatPlayersPlayerEn,
  messagesRookstatSpecialCodesEn,
  messagesRookstatWorldsEn,

  messagesRulesEn,

  messagesThreadsNewEn,
  messagesThreadsNewPostEn,
  messagesThreadsThreadEn,
  messagesThreadsEditEn,
  messagesThreadsEditPostEn,

  messagesUsersIndexEn,
  messagesUsersUserEn,

  messagesWarningsNewEn
]

const messagesPl: object[] = [
  messagesFooterPl,
  messagesPagerPl,
  messagesScorePl,
  messagesUploadPl,
  messagesUsernamePl,

  messagesAccountIndexPl,
  messagesAccountAnonymizePl,
  messagesAccountChangeAvatarPl,
  messagesAccountChangeEmailPl,
  messagesAccountChangePasswordPl,
  messagesAccountLoginPl,
  messagesAccountRegisterPl,
  messagesAccountRequestActivationPl,
  messagesAccountResetPasswordPl,
  messagesAccountRevokeSessionsPl,
  messagesAccountWarningsPl,

  messagesBlogBlogPl,
  messagesBlogEditPl,
  messagesBlogIndexPl,
  messagesBlogNewPl,

  messagesCalculatorsIndexPl,
  messagesCalculatorsAgeCalculatorPl,
  messagesCalculatorsEquipmentLootBonusCalculatorPl,
  messagesCalculatorsFishingCalculatorPl,
  messagesCalculatorsHuntingTimeCalculatorPl,
  messagesCalculatorsPartyCalculatorPl,
  messagesCalculatorsPlayerStatsCalculatorPl,
  messagesCalculatorsStaminaCalculatorPl,

  messagesErrorPl,

  messagesImagesEditPl,
  messagesImagesImagePl,
  messagesImagesIndexPl,
  messagesImagesNewPl,

  messagesForumsForumPl,

  messagesFansiteItemPl,

  messagesForumsIndexPl,

  messagesLibraryIndexPl,
  messagesLibraryAchievementsAchievementPl,
  messagesLibraryAchievementsIndexPl,
  messagesLibraryBooksIndexPl,
  messagesLibraryBooksBookPl,
  messagesLibraryItemsIndexPl,
  messagesLibraryItemsAmuletsPl,
  messagesLibraryItemsArmorsPl,
  messagesLibraryItemsBootsPl,
  messagesLibraryItemsContainersPl,
  messagesLibraryItemsDistanceWeaponsPl,
  messagesLibraryItemsFoodPl,
  messagesLibraryItemsHelmetsPl,
  messagesLibraryItemsLegsPl,
  messagesLibraryItemsLiquidsPl,
  messagesLibraryItemsMeleeWeaponsPl,
  messagesLibraryItemsOthersPl,
  messagesLibraryItemsRingsPl,
  messagesLibraryItemsShieldsPl,
  messagesLibraryItemsToolsPl,
  messagesLibraryItemsValuablesPl,
  messagesLibraryItemsCreatureProductsPl,
  messagesLibraryItemsCurrencyPl,
  messagesLibraryItemsItemPl,
  messagesLibraryCreaturesIndexPl,
  messagesLibraryCreaturesCreaturePl,
  messagesLibraryNpcsIndexPl,
  messagesLibraryNpcsNpcPl,

  messagesPostsIndexPl,

  messagesPrivacyPolicyPl,

  messagesRookstatAdvancementsPl,
  messagesRookstatBoostedCreaturesPl,
  messagesRookstatBossesPl,
  messagesRookstatDeathsPl,
  messagesRookstatFrequentlyAskedQuestionsPl,
  messagesRookstatIndexPl,
  messagesRookstatPlayersIndexPl,
  messagesRookstatPlayersPlayerPl,
  messagesRookstatSpecialCodesPl,
  messagesRookstatWorldsPl,

  messagesRulesPl,

  messagesThreadsNewPl,
  messagesThreadsNewPostPl,
  messagesThreadsThreadPl,
  messagesThreadsEditPl,
  messagesThreadsEditPostPl,

  messagesUsersIndexPl,
  messagesUsersUserPl,

  messagesWarningsNewPl
]

let en = messagesBaseEn
let pl = messagesBasePl

for (const messageEn of messagesEn) {
  en = deepmerge(en, messageEn)
}

for (const messagePl of messagesPl) {
  pl = deepmerge(pl, messagePl)
}

export default createI18n({
  locale,
  fallbackLocale: 'en',
  messages: { pl, en }
})
