export default {
  'view.calculators.fishingCalculator.title': 'Fishing Calculator',

  'view.calculators.fishingCalculator.fromLabel': 'From ({min}-{max})',
  'view.calculators.fishingCalculator.fromPercentToNextLabel': '% To Next ({min}-{max})',
  'view.calculators.fishingCalculator.toLabel': 'To ({min}-{max})',
  'view.calculators.fishingCalculator.toPercentToNextLabel': '% To Next ({min}-{max})',

  'view.calculators.fishingCalculator.calculateButton': 'Calculate',

  'view.calculators.fishingCalculator.attemptsText': 'Attempts',
  'view.calculators.fishingCalculator.wormsText': 'Worms',
  'view.calculators.fishingCalculator.timeText': 'Time',
  'view.calculators.fishingCalculator.dateText': 'Date'
}
