export default {
  'view.blog.index.title': 'Blog',

  'view.blog.index.featuredBlogLabel': 'Polecany blog',
  'view.blog.index.ongoingSeriesLabel': 'Aktywne serie',
  'view.blog.index.keywordsLabel': 'Słowa kluczowe',

  'view.blog.index.searchLabel': 'Szukaj',

  'view.blog.index.featuredLabel': 'Polecany',
  'view.blog.index.featuredAllOption': 'Dowolny',
  'view.blog.index.featuredFeaturedOption': 'Polecany',
  'view.blog.index.featuredUnfeaturedOption': 'Niewyróżniony',

  'view.blog.index.tagLabel': 'Tag',
  'view.blog.index.tagAllOption': 'Dowolny',
  'view.blog.index.tagArticleOption': 'Artykuł',
  'view.blog.index.tagExclusiveOption': 'Exclusive',
  'view.blog.index.tagFansiteDevOption': 'Fansite Dev',
  'view.blog.index.tagFeaturedTheRookstayerOption': 'The Rookstayer',
  'view.blog.index.tagFeaturedAdvancementsOption': 'Awanse',
  'view.blog.index.tagFeaturedTheTimeMachineOption': 'Wehikuł czasu',
  'view.blog.index.tagFeaturedWhereToLevelUpOption': 'Gdzie expić?',
  'view.blog.index.tagInterviewOption': 'Wywiad',
  'view.blog.index.tagNewsOption': 'Newsy',
  'view.blog.index.tagTutorialOption': 'Poradnik',

  'view.blog.index.languageLabel': 'Język',

  'view.blog.index.sortLabel': 'Sortowanie',
  'view.blog.index.sortCreatedAtAscOption': 'Data publikacji ― Rosnąco',
  'view.blog.index.sortCreatedAtDescOption': 'Data publikacji ― Malejąco',
  'view.blog.index.sortViewsAscOption': 'Odwiedziny ― Rosnąco',
  'view.blog.index.sortViewsDescOption': 'Odwiedziny ― Malejąco',

  'view.blog.index.descriptionByText': 'przez',
  'view.blog.index.descriptionInText': 'w',
  'view.blog.index.descriptionCommentsText': 'komentarzy | komentarz | komentarze',
  'view.blog.index.descriptionViewsText': 'odsłon | odsłona | odsłony',

  'view.blog.index.emptyListText': 'Sorry, but no results match your search criteria.'
}
