export default {
  'view.calculators.fishingCalculator.title': 'Kalkulator wędkarstwa',

  'view.calculators.fishingCalculator.fromLabel': 'Od ({min}-{max})',
  'view.calculators.fishingCalculator.fromPercentToNextLabel': '% do nast. ({min}-{max})',
  'view.calculators.fishingCalculator.toLabel': 'Do ({min}-{max})',
  'view.calculators.fishingCalculator.toPercentToNextLabel': '% do nast. ({min}-{max})',

  'view.calculators.fishingCalculator.calculateButton': 'Oblicz',

  'view.calculators.fishingCalculator.attemptsText': 'Próby',
  'view.calculators.fishingCalculator.wormsText': 'Robaki',
  'view.calculators.fishingCalculator.timeText': 'Czas',
  'view.calculators.fishingCalculator.dateText': 'Data'
}
