import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx, Transition as _Transition, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "section" }
const _hoisted_2 = { class: "block" }
const _hoisted_3 = { class: "container is-narrow" }
const _hoisted_4 = { class: "columns is-mobile is-vcentered" }
const _hoisted_5 = { class: "column is-size-7" }
const _hoisted_6 = { class: "column is-narrow" }
const _hoisted_7 = { class: "is-flex" }
const _hoisted_8 = { class: "image is-16x16 has-border-radius-rounded is-loadable" }
const _hoisted_9 = { class: "image is-16x16 has-border-radius-rounded is-loadable" }
const _hoisted_10 = { class: "container is-narrow" }
const _hoisted_11 = { class: "columns is-centered" }
const _hoisted_12 = { class: "column" }
const _hoisted_13 = { class: "is-flex is-align-items-center is-justify-content-center" }
const _hoisted_14 = { class: "has-text-centered is-flex-grow-1 mx-5" }
const _hoisted_15 = { class: "tags is-centered mb-0" }
const _hoisted_16 = { class: "tag is-primary" }
const _hoisted_17 = { class: "has-white-space-nowrap" }
const _hoisted_18 = {
  class: "block has-text-centered",
  style: {"margin":"1.05rem"}
}
const _hoisted_19 = { class: "section" }
const _hoisted_20 = { class: "block" }
const _hoisted_21 = { class: "container" }
const _hoisted_22 = { class: "has-text-centered has-text-grey-lighter" }
const _hoisted_23 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_toaster = _resolveComponent("v-toaster")!
  const _component_v_navbar = _resolveComponent("v-navbar")!
  const _component_v_image = _resolveComponent("v-image")!
  const _component_fa_icon = _resolveComponent("fa-icon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_v_spinner = _resolveComponent("v-spinner")!
  const _component_v_state = _resolveComponent("v-state")!
  const _component_v_breadcrumbs = _resolveComponent("v-breadcrumbs")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_v_footer = _resolveComponent("v-footer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_v_toaster),
    _createVNode(_component_v_navbar),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("em", null, [
                _createTextVNode(_toDisplayString(_ctx.$t('app.locale.youAreBrowsingFansiteInText')) + " ", 1),
                _createElementVNode("strong", null, _toDisplayString(_ctx.$t(`app.locale.${_ctx.locale}Text`)), 1),
                _createTextVNode(".")
              ])
            ]),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("a", {
                  role: "button",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setLocaleLanguage('pl'))),
                  class: "mr-1"
                }, [
                  _createElementVNode("figure", _hoisted_8, [
                    _createVNode(_component_v_image, {
                      image: "flags/Polish.svg",
                      alt: "Polish"
                    })
                  ])
                ]),
                _createElementVNode("a", {
                  role: "button",
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.setLocaleLanguage('en')))
                }, [
                  _createElementVNode("figure", _hoisted_9, [
                    _createVNode(_component_v_image, {
                      image: "flags/English.svg",
                      alt: "English"
                    })
                  ])
                ])
              ])
            ])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_10, [
        _createVNode(_component_v_state, {
          data: _ctx.charactersOnline !== undefined && _ctx.boostedCreature && _ctx.latestFansiteContest
        }, {
          resolved: _withCtx(() => [
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("div", _hoisted_12, [
                _createElementVNode("div", _hoisted_13, [
                  _createElementVNode("div", null, [
                    _createElementVNode("a", {
                      onClick: _cache[2] || (_cache[2] = ($event: any) => {_ctx.slide = (((_ctx.slide - 1) % _ctx.sliderElements.length) + _ctx.sliderElements.length) % _ctx.sliderElements.length; _ctx.slideDirection = 'left'}),
                      class: "icon",
                      role: "button"
                    }, [
                      _createVNode(_component_fa_icon, { icon: ['fas', 'chevron-left'] })
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_14, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sliderElements, ({ translationKey, to, value }, index) => {
                      return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
                        (_ctx.slide === index)
                          ? (_openBlock(), _createBlock(_Transition, {
                              key: 0,
                              name: _ctx.transition,
                              mode: "in-out",
                              appear: ""
                            }, {
                              default: _withCtx(() => [
                                _createElementVNode("div", null, [
                                  _createElementVNode("div", _hoisted_15, [
                                    _createElementVNode("span", _hoisted_16, _toDisplayString(_ctx.$t(translationKey)), 1)
                                  ]),
                                  _createElementVNode("div", _hoisted_17, [
                                    _createVNode(_component_router_link, { to: to }, {
                                      default: _withCtx(() => [
                                        _createElementVNode("strong", null, _toDisplayString(value), 1)
                                      ]),
                                      _: 2
                                    }, 1032, ["to"])
                                  ])
                                ])
                              ]),
                              _: 2
                            }, 1032, ["name"]))
                          : _createCommentVNode("", true)
                      ], 64))
                    }), 128))
                  ]),
                  _createElementVNode("div", null, [
                    _createElementVNode("a", {
                      onClick: _cache[3] || (_cache[3] = ($event: any) => {_ctx.slide = (_ctx.slide + 1) % _ctx.sliderElements.length; _ctx.slideDirection = 'right'}),
                      class: "icon",
                      role: "button"
                    }, [
                      _createVNode(_component_fa_icon, { icon: ['fas', 'chevron-right'] })
                    ])
                  ])
                ])
              ])
            ])
          ]),
          unresolved: _withCtx(() => [
            _createElementVNode("div", _hoisted_18, [
              _createVNode(_component_v_spinner)
            ])
          ]),
          _: 1
        }, 8, ["data"])
      ])
    ]),
    _createVNode(_component_v_breadcrumbs),
    _createElementVNode("div", _hoisted_19, [
      _createElementVNode("div", _hoisted_20, [
        _createVNode(_component_router_view)
      ]),
      _createElementVNode("div", _hoisted_21, [
        _createElementVNode("div", _hoisted_22, [
          _createElementVNode("small", null, [
            _createElementVNode("em", {
              innerHTML: _ctx.$t('app.usersText', { viewers: Math.max(_ctx.viewers, _ctx.users), users: _ctx.users }) + _ctx.$t('app.usersLoggedInText', { count: _ctx.users })
            }, null, 8, _hoisted_23)
          ])
        ])
      ])
    ]),
    _createVNode(_component_v_footer)
  ], 64))
}