export default {
  'view.blog.index.title': 'Blog',

  'view.blog.index.featuredBlogLabel': 'Featured Blog',
  'view.blog.index.ongoingSeriesLabel': 'Ongoing Series',
  'view.blog.index.keywordsLabel': 'Keywords',

  'view.blog.index.searchLabel': 'Search',

  'view.blog.index.featuredLabel': 'Featured',
  'view.blog.index.featuredAllOption': 'All',
  'view.blog.index.featuredFeaturedOption': 'Featured',
  'view.blog.index.featuredUnfeaturedOption': 'Unfeatured',

  'view.blog.index.tagLabel': 'Tag',
  'view.blog.index.tagAllOption': 'All',
  'view.blog.index.tagArticleOption': 'Article',
  'view.blog.index.tagExclusiveOption': 'Exclusive',
  'view.blog.index.tagFansiteDevOption': 'Fansite Dev',
  'view.blog.index.tagFeaturedTheRookstayerOption': 'The Rookstayer',
  'view.blog.index.tagFeaturedAdvancementsOption': 'Advancements',
  'view.blog.index.tagFeaturedTheTimeMachineOption': 'The Time Machine',
  'view.blog.index.tagFeaturedWhereToLevelUpOption': 'Where To Level Up?',
  'view.blog.index.tagInterviewOption': 'Interview',
  'view.blog.index.tagNewsOption': 'News',
  'view.blog.index.tagTutorialOption': 'Tutorial',

  'view.blog.index.languageLabel': 'Language',

  'view.blog.index.sortLabel': 'Sort',
  'view.blog.index.sortCreatedAtAscOption': 'Created At ― Ascending',
  'view.blog.index.sortCreatedAtDescOption': 'Created At ― Descending',
  'view.blog.index.sortViewsAscOption': 'Views ― Ascending',
  'view.blog.index.sortViewsDescOption': 'Views ― Descending',

  'view.blog.index.descriptionByText': 'by',
  'view.blog.index.descriptionInText': 'in',
  'view.blog.index.descriptionCommentsText': 'comments',
  'view.blog.index.descriptionViewsText': 'views',

  'view.blog.index.emptyListText': 'Sorry, but no results match your search criteria.'
}
