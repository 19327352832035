import { library } from '@fortawesome/fontawesome-svg-core'

import {
  faChevronLeft,
  faChevronRight,
  faPaperPlane,
  faTimes,
  faEnvelope,
  faCookieBite,
  faSun,
  faMoon,
  faArrowUp,
  faArrowDown,
  faArrowLeft,
  faArrowRight,
  faCircleUser,
  faLock,
  faCircleQuestion,
  faRss,
  faBook,
  faComments,
  faUsers,
  faShield,
  faCalculator,
  faCloudArrowUp,
  faScaleBalanced,
  faShieldHeart,
  faKey,
  faHandshake,
  faMagnifyingGlass,
  faFilter,
  faListUl,
  faFlag,
  faBold,
  faUnderline,
  faItalic,
  faImage,
  faLink,
  faListOl,
  faQuoteLeft,
  faAt,
  faEarthEurope,
  faVihara,
  faUserNinja,
  faCheck,
  faCircleCheck,
  faHighlighter,
  faTrashCan,
  faArrowUpRightFromSquare,
  faSpinner,
  faMapSigns,
  faPaw,
  faCircle,
  faHandshakeAngle,
  faPowerOff,
  faSortNumericUp,
  faCheckCircle,
  faInfoCircle,
  faExclamationCircle,
  faTimesCircle,
  faUpload,
  faThumbtack,
  faCalendarDays,
  faRankingStar,
  faStar,
  faStopwatch,
  faHeart,
  faEye,
  faFlask,
  faComment,
  faDog,
  faMusic
} from '@fortawesome/free-solid-svg-icons'
import {
  faCircleCheck as farCircleCheck,
  faComment as farComment
} from '@fortawesome/free-regular-svg-icons'
import {
  faInstagram,
  faDiscord,
  faFacebookMessenger,
  faFacebookF
} from '@fortawesome/free-brands-svg-icons'

library.add(...[
  faChevronLeft,
  faChevronRight,
  faPaperPlane,
  faTimes,
  faEnvelope,
  faCookieBite,
  faSun,
  faMoon,
  faArrowUp,
  faArrowDown,
  faArrowLeft,
  faArrowRight,
  faCircleUser,
  faLock,
  faCircleQuestion,
  faRss,
  faBook,
  faComments,
  faUsers,
  faShield,
  faCalculator,
  faCloudArrowUp,
  faScaleBalanced,
  faShieldHeart,
  faKey,
  faHandshake,
  faMagnifyingGlass,
  faFilter,
  faListUl,
  faFlag,
  faBold,
  faUnderline,
  faItalic,
  faImage,
  faLink,
  faListOl,
  faQuoteLeft,
  faAt,
  faEarthEurope,
  faVihara,
  faUserNinja,
  faCheck,
  faCircleCheck,
  faHighlighter,
  faTrashCan,
  faArrowUpRightFromSquare,
  faSpinner,
  faMapSigns,
  faPaw,
  faCircle,
  faHandshakeAngle,
  faPowerOff,
  faSortNumericUp,
  faCheckCircle,
  faInfoCircle,
  faExclamationCircle,
  faTimesCircle,
  faUpload,
  faThumbtack,
  faCalendarDays,
  faRankingStar,
  faStar,
  faStopwatch,
  faHeart,
  faEye,
  farCircleCheck,
  faInstagram,
  faDiscord,
  faFacebookMessenger,
  faFacebookF,
  faFlask,
  faComment,
  farComment,
  faDog,
  faMusic
])
