export default {
  'view.blog.new.title': 'New Blog',

  'view.blog.new.titleLabel': 'Title',
  'view.blog.new.tagLabel': 'Tag',

  'view.blog.new.tagArticleOption': 'Article',
  'view.blog.new.tagExclusiveOption': 'Exclusive',
  'view.blog.new.tagFansiteDevOption': 'Fansite Dev',
  'view.blog.new.tagFeaturedTheRookstayerOption': 'The Rookstayer',
  'view.blog.new.tagFeaturedAdvancementsOption': 'Advancements',
  'view.blog.new.tagFeaturedTheTimeMachineOption': 'The Time Machine',
  'view.blog.new.tagFeaturedWhereToLevelUpOption': 'Where To Level Up?',
  'view.blog.new.tagInterviewOption': 'Interview',
  'view.blog.new.tagNewsOption': 'News',
  'view.blog.new.tagTutorialOption': 'Tutorial',

  'view.blog.new.languageLabel': 'Language',

  'view.blog.new.featuredLabel': 'Featured',

  'view.blog.new.featuredYesOption': 'Yes',
  'view.blog.new.featuredNoOption': 'No',

  'view.blog.new.contentLabel': 'Content',
  'view.blog.new.previewLabel': 'Preview',

  'view.blog.new.previewButton': 'Preview',
  'view.blog.new.addBlogButton': 'Add Blog',

  'view.blog.new.createBlogToast': 'You have successfully published a blog.'
}
